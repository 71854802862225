import * as React from 'react';
import LayoutDisplay from "../layout";
import {Descriptions,Table,Tabs,Tag} from 'antd'
import rpc from "../rpc";
import utils, {pageInterVar} from "../utils";
import config from "../config";
const {TabPane} = Tabs;
function callback(){}
const columnsH = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width:"15%"
    },
    {
        title: 'Hashrate (rough, short average)',
        dataIndex: 'short',
        key: 'short',
        width:"30%"
    },
    {
        title: 'Hashrate (accurate, long average)',
        dataIndex: 'long',
        key: 'long',
        width:"30%"
    },
    {
        title: 'Last Beat',
        dataIndex: 'lastBeat',
        key: 'lastBeat',
        width:"25%"
    }
];

const paymentColumns = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width:"20%"
    },
    {
        title: 'Tx ID',
        dataIndex: 'txId',
        key: 'txId',
        width:"50%"
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width:"30%"
    },
];

class Details extends React.Component<any, any>{

    state = {
        data:undefined,
        dataSourceH:[],
        dataSourceP:[],
        address:""
    }
    componentDidMount() {
        pageInterVar.start(()=>{
            this.init().catch(e=>{console.error(e)})
        },3000)
    }

    init = async ()=>{
        let address = this.props.match.params.address
        if(!address){
            address = localStorage.getItem("miner_address");
        }else{
            localStorage.setItem("miner_address",address);
        }
        if(address){
            const rest = await rpc.accounts(address)
            this.setState({
                data:rest,
                address:address
            })
            this.convertData(rest)
        }
    }

    convertData = (data:any)=>{
        const arr = [];
        let workers = data["workers"];
        const payments = data["payments"]
        const dataSourceP = [];
        if(payments){
            for(let p of payments){
                dataSourceP.push({
                    txId:<a href={`${config.url.explorer.tx}${p.tx}`} target="_blank">{p.tx}</a>,
                    time:utils.formatDate(new Date(p.timestamp*1000)),
                    amount:<Tag color="blue">{utils.fromValue(p.amount,9).toString(10)} SERO</Tag>
                })
            }
        }
        const keys = Object.keys(workers);
        let i = 1;
        for(let k of keys){
            const d = workers[k];
            arr.push({
                id: <Tag color="gold">{k}</Tag>,
                key: i++,
                short: <Tag color="magenta">{utils.nFormatter(d["hr"],3)}</Tag>,
                long: <Tag color="magenta">{utils.nFormatter(d["hr2"], 3)}</Tag>,
                lastBeat: `${utils.dateAge(d.lastBeat)}`,
            })
        }
        console.log(arr)
        this.setState({
            dataSourceH:arr,
            dataSourceP: dataSourceP
        })
    }

    render() {
        const {data,dataSourceH,dataSourceP, address} = this.state;
        return (
            <LayoutDisplay menuKey="accounts">
                {
                    data && data["stats"] && <>
                        <div className="tab-info">
                            <Descriptions title={<span>Miner Detail [<small style={{color:"#1890ff"}}>{address}</small>]</span>}>
                                <Descriptions.Item label="Immature Balance"><Tag color="blue">{utils.fromValue(data["stats"]["immature"],9).toString(10)}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Last Share Submitted"><Tag color="blue">{utils.dateAge(data["stats"]["lastShare"])}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Blocks Found"><Tag color="blue">{data["stats"]["blocksFound"]}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Pending Balance"><Tag color="blue">{utils.fromValue(data["stats"]["balance"],9).toString(10)}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Hashrate (30m)"><Tag color="magenta">{utils.nFormatter(data["currentHashrate"],3)}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Hashrate (3h)"><Tag color="magenta">{utils.nFormatter(data["hashrate"],3)}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Workers Online"><Tag color="blue">{data["workersOnline"]}</Tag></Descriptions.Item>
                                {/*<Descriptions.Item label="Your Round Share"><Tag color="blue">{utils.fromValue(data["roundShares"],9).toString(10)}%</Tag></Descriptions.Item>*/}
                                <Descriptions.Item label="Total Payments:"><Tag color="blue">{data["paymentsTotal"]}</Tag></Descriptions.Item>
                                <Descriptions.Item label="Total Paid"><Tag color="blue">{utils.fromValue(data["stats"]["paid"],9).toString(10)}</Tag></Descriptions.Item>
                                {/*<Descriptions.Item label="Epoch Switch">506.77937584</Descriptions.Item>*/}
                            </Descriptions>
                        </div>
                        <div className="tab-info">

                            <Tabs defaultActiveKey="1" onChange={callback}>
                                <TabPane tab="Workers" key="1">
                                    <div>
                                        <Table dataSource={dataSourceH} columns={columnsH} scroll={{x:true,y:"50%",scrollToFirstRowOnChange:true}} pagination={{pageSize:20,showSizeChanger:false }} size="small"/>
                                    </div>
                                </TabPane>
                                <TabPane tab="Payouts" key="2">
                                    <div>
                                        <Table dataSource={dataSourceP} columns={paymentColumns} scroll={{x:true,y:"50%",scrollToFirstRowOnChange:true}} pagination={{ pageSize:20,showSizeChanger:false }} size="small"/>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </>
                }


            </LayoutDisplay>
        );
    }

}

export default Details