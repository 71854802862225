import BigNumber from "bignumber.js";
import selfStorage from "./storage";
class Utils {

    fromValue = (v:any,n:number = 18):BigNumber=>{
        return new BigNumber(v).dividedBy(10**n)
    }
    formatDateNum = (n:number)=>{
        return this.formatDate(new Date(n*1000))
    }

    formatDate = (date:Date)=>{
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    }

    nFormatter = (n: number | BigNumber | string | undefined, digits: number) =>{
        if(!n){
            return "0"
        }
        const num = new BigNumber(n).toNumber();
        const si = [
            {value: 1, symbol: ""},
            {value: 1E3, symbol: "KH"},
            {value: 1E6, symbol: "MH"},
            {value: 1E9, symbol: "GH"},
            {value: 1E12, symbol: "TH"},
            {value: 1E15, symbol: "PH"},
            {value: 1E18, symbol: "EH"}
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + " " +si[i].symbol;
    }

    dateAge = (seconds:number) =>{
        if(seconds === 0){
            return ""
        }
        const now = Math.ceil(Date.now()/1000);
        const s = now - seconds;

        const h = Math.floor(s/60/60);
        const m = Math.floor( s/60)
        if(s<=0){
            return "now"
        }
        const ss = s-m*60;
        return h>0? `${h}h ${m}m ${ss}s ago`:m>0?`${m}m ${ss}s ago`:`${ss}s ago`
    }
}


class Interval {

    key: string = "initInterValId"

    interValId: number | undefined


    constructor(key:string) {
        this.interValId = selfStorage.getItem(this.key)
        this.key = key;
    }

    start(fn: Function, t: number) {
        fn();
        this.stop();
        this.interValId = window.setInterval(() => {
            fn()
        }, t)
        selfStorage.setItem(this.key, this.interValId);
    }

    stop() {
        if (this.interValId) {
            selfStorage.removeItem(this.key)
            clearInterval(this.interValId)
        }

    }
}

const statsInterVar = new Interval("statsInterVar");
const pageInterVar = new Interval("pageInterVar");

const utils = new Utils()
export default utils;

export {
    pageInterVar,statsInterVar
}