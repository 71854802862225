import * as React from "react";
import {Layout,Menu,Tag,Badge} from 'antd';
import './index.css';
const { Header, Content, Footer } = Layout;

interface Props{
    menuKey:string
    blockNum?:number
}
class LayoutDisplay extends React.Component<Props, any>{

    render() {
        const {menuKey,blockNum} = this.props;
        return (
            <Layout>
                <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <div className="logo" >
                        <img src="./logo.png" width="48"/><b>CARTOM POOL</b>
                    </div>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[menuKey]} onSelect={({ item, key, keyPath, selectedKeys, domEvent })=>{
                        this.setState({
                            selectKey:key
                        })
                        window.location.href=`#${key}`;
                    }}>
                        <Menu.Item key="home">Home</Menu.Item>
                        <Menu.Item key="blocks">Pool Blocks&nbsp;{blockNum && blockNum>0 ? <Badge count={blockNum} overflowCount={9999999}  style={{fontWeight:800,backgroundColor:"#dff0d8",color:"#3c763d",borderColor:"#d6e9c6"}}/>:""}</Menu.Item>
                        {localStorage.getItem("miner_address") && <Menu.Item key="accounts">Account</Menu.Item>}
                        <Menu.Item key="help">Help</Menu.Item>
                    </Menu>
                </Header>
                <Content className="content">
                  <div className="content-in">
                      {this.props.children}
                  </div>
                </Content>
                <Footer className="footer">SERO Community</Footer>
            </Layout>
        );
    }
}

export default LayoutDisplay