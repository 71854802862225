import * as React from 'react';
import {Table, Tag, Badge,Tabs} from 'antd';
import LayoutDisplay from "../layout";
import utils, {pageInterVar} from "../utils";
import rpc from "../rpc";
import BigNumber from "bignumber.js";
import config from "../config";

const {TabPane} = Tabs;

const columnsOrigin = [
    {
        title: 'Height',
        dataIndex: 'height',
        key: 'height',
        width:"10%"
    },
    {
        title: 'Block Hash',
        dataIndex: 'hash',
        key: 'hash',
        width:"40%"
    },
    {
        title: 'Time Found',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width:"20%"
    },
    {
        title: 'Variance',
        dataIndex: 'variance',
        key: 'variance',
        width:"10%"
    },
    {
        title: 'Reward',
        dataIndex: 'reward',
        key: 'reward',
        width:"10%"
    }
];


const columnsH = [
    {
        title: 'Blocks',
        dataIndex: 'blocks',
        key: 'blocks',
        width:"33%"
    },
    {
        title: 'Shares/Diff',
        dataIndex: 'shares',
        key: 'shares',
        width:"33%"
    },
    {
        title: 'Orphan Rate',
        dataIndex: 'orphan',
        key: 'orphan',
        width:"33%"
    }
];



class Blocks extends React.Component<any, any> {

    state = {
        data: undefined,
        dataSource: [],
        key: "1",
        columns : columnsOrigin,
        dataSourceH:[]
    }

    componentDidMount() {
        pageInterVar.start(()=>{
            this.init().catch(e=>{console.error(e)})
        },3000)
    }

    init = async () => {
        const data = await rpc.blocks();

        this.setState({
            data: data,
            dataSource: this.convertDataSource(data),
            dataSourceH:this.convertLuck(data)
        })
    }

    convertLuck = (data:any)=>{
        const luck = data["luck"];
        const keys = Object.keys(luck);
        const arr = []
        let i=1;
        for(let k of keys){
            arr.push({
                key: i++,
                blocks: k,
                shares: `${utils.nFormatter(luck[k]["luck"]*100,2)}%`,
                orphan: `${utils.nFormatter(luck[k]["orphanRate"]*100,2)}%`,
            })
        }
        return arr
    }

    convertDataSource = (data: any, key?: string) => {
        const dataSource = [];
        let arr: Array<any> = [];
        if (key) {
            this.setState({key: key})
        }else {
            key = this.state.key;
        }
        if(key === "1"){
            arr = data.matured;
        }else if(key === "2"){
            arr = data.immature;
        }else if (key === "3") {
            arr = data.candidates;
        }
        let i = 1;
        if(arr && arr.length>0){
            for (let a of arr) {
                const variance = new BigNumber(a.shares).dividedBy(a.difficulty).multipliedBy(100)
                dataSource.push({
                    key: i++,
                    height: a.height,
                    hash: a["orphan"]? <Tag color="#cd201f">Orphan</Tag>:<a href={`${config.url.explorer.block}${a.hash}`} target="_blank">{a.hash}</a>,
                    timestamp: utils.formatDate(new Date(parseInt(a.timestamp) * 1000)),
                    variance: <Tag color={variance.toNumber()>100?"success":"warning"}>{variance.toFixed(0)}%</Tag>,
                    reward: <Tag color="blue">{utils.nFormatter(utils.fromValue(a.reward).toNumber(), 6)}</Tag>,
                })
            }
        }
        return dataSource;
    }

    render() {
        const {data, dataSource, key,columns,dataSourceH} = this.state;
        return (
            <LayoutDisplay menuKey="blocks" blockNum={data?(new BigNumber(data["immatureTotal"]).plus(data["candidatesTotal"]).toNumber()):0}>
                <div className="tab-info">
                    <Table dataSource={dataSourceH} columns={columnsH}
                           scroll={{x: true, y: "50%", scrollToFirstRowOnChange: true}} pagination={{position: []}}
                           size="small"/>
                </div>

                <div className="tab-info">
                    <Tabs defaultActiveKey={key} onChange={(v)=>{
                        const rest = this.convertDataSource(data,v);
                        const col = v === "3" ? [columnsOrigin[0],columnsOrigin[2],columnsOrigin[3]]:columnsOrigin;
                        console.log(col,v,typeof v,v == "3")
                        this.setState({
                            dataSource:rest,
                            columns: col
                        })
                    }}>
                        <TabPane tab={<span>Blocks <Badge overflowCount={9999999} style={{backgroundColor:"#dff0d8",color:"#3c763d",borderColor:"#d6e9c6"}} count={data?data["maturedTotal"]:0}/></span>} key="1">
                            <div>
                                <Table dataSource={dataSource} columns={columns}
                                       scroll={{x: true, y: "50%", scrollToFirstRowOnChange: true}} size="small" pagination={{pageSize:20,showSizeChanger:false}}/>
                            </div>
                        </TabPane>
                        <TabPane  tab={<span>Immature <Badge overflowCount={9999999} style={{backgroundColor:"#dff0d8",color:"#3c763d",borderColor:"#d6e9c6"}}  count={data?data["immatureTotal"]:0}/></span>} key="2">
                            <div>
                                <Table dataSource={dataSource} columns={columns}
                                       scroll={{x: true, y: "50%", scrollToFirstRowOnChange: true}} size="small" pagination={{pageSize:20,showSizeChanger:false}}/>
                            </div>
                        </TabPane>
                        <TabPane tab={<span>New Blocks <Badge overflowCount={9999999}  style={{backgroundColor:"#d9edf7",color:"#31708f",borderColor:"#bce8f1"}}  count={data?data["candidatesTotal"]:0}/></span>} key="3">
                            <div>
                                <Table dataSource={dataSource} columns={columns}
                                       scroll={{x: true, y: "50%", scrollToFirstRowOnChange: true}} size="small" pagination={{pageSize:20,showSizeChanger:false}}/>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>

            </LayoutDisplay>
        );
    }
}

export default Blocks