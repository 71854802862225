import * as React from 'react';
import LayoutDisplay from "../layout";
import {Table,Descriptions,Tag} from 'antd';
import utils, {pageInterVar, statsInterVar} from "../utils";
import "./index.css"
import rpc from "../rpc";
import BigNumber from "bignumber.js";
const columnsH = [
    {
        title: 'Login',
        dataIndex: 'address',
        key: 'address',
        width: "50%",
    },
    {
        title: 'Hash Rate',
        dataIndex: 'hashRate',
        key: 'hashRate',
        width: "25%",
    },
    {
        title: 'Last Beat',
        dataIndex: 'lastBeat',
        key: 'lastBeat',
        width: "25%",
    }
];

class Miners extends React.Component<any, any>{

    state = {
        data:undefined,
        dataSourceH:[]
    }
    componentDidMount() {
        pageInterVar.start(()=>{
            this.init().catch(e=>{console.error(e)})
        },3000)
    }

    init = async ()=>{
        const key = this.props.match.params.key
        const rest:any = await rpc.miners(key);
        const miners = Object.keys(rest.miners);
        const arr = [];
        for(let addr of miners){
            const d = rest.miners[addr];
            arr.push({
                address: <a className="address-break" href={`./#/account/${addr}`}>{addr}</a>,
                hashRate: <Tag color="magenta">{utils.nFormatter(d["hr"],2)}</Tag>,
                lastBeat: utils.formatDate(new Date(d["lastBeat"]*1000)),
                hr:d["hr"]
            })
        }
        arr.sort((a, b)=>{
            if(new BigNumber(a.hr).comparedTo(new BigNumber(b.hr)) > 0){
                return -1
            }
            return 1
        })

        this.setState({
            data:rest,
            dataSourceH:arr
        })
    }


    render() {
        const {data,dataSourceH} = this.state;
        return (
            <LayoutDisplay menuKey="miners">
                <div className="tab-info">
                    <Descriptions title="Miner Info">
                        <Descriptions.Item label="Total Hash Rate"><Tag color="magenta">{data?utils.nFormatter(data["hashrate"],2):0}</Tag></Descriptions.Item>
                        <Descriptions.Item label="Total miners"><Tag color="blue">{data?data["minersTotal"]:0}</Tag></Descriptions.Item>
                    </Descriptions>
                </div>
                <div className="tab-info">
                    <Table dataSource={dataSourceH} columns={columnsH} scroll={{x:true,y:"50%",scrollToFirstRowOnChange:true}} size="small" pagination={{pageSize: 20,showSizeChanger:false}}/>
                </div>


            </LayoutDisplay>
        );
    }
}

export default Miners