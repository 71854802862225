class Config{

    url = {
        explorer:{
            block:"https://explorer.sero.cash/blockInfo.html?hash=",
            tx: "https://explorer.sero.cash/txsInfo.html?hash="
        },
        poolNode:{
            api: "https://cartompool.com"
        }
    }
}

const config = new Config()

export default config