import * as React from 'react';
import LayoutDisplay from "../layout";
import {Typography, Table ,Row,Col} from "antd";

const { Title, Paragraph, Text, Link } = Typography;

const columns = [
    {
        title: 'Items',
        dataIndex: 'item',
        key: 'item',
        width:"15%"
    },
    {
        title: 'Details',
        dataIndex: 'detail',
        key: 'detail',
        width:"30%"
    }
]

const datasource = [
    {"item":<span style={{fontWeight:500}}>Website</span>,"detail":<a href="https://sero.cash" target="_blank" style={{fontWeight:600}}>Sero.cash</a>},
    {"item":<span style={{fontWeight:500}}>Block explorer</span>,"detail":<a href="https://explorer.sero.cash" target="_blank" style={{fontWeight:600}}>Explorer.sero.cash</a>},
    // {"item":<span style={{fontWeight:500}}>Consensus</span>,"detail":"PoW + PoS"},
    {"item":<span style={{fontWeight:500}}>Mining device</span>,"detail":"GPU"},
    {"item":<span style={{fontWeight:500}}>Official channel</span>,"detail":<a href="https://t.me/SeroOfficial" target="_blank" style={{fontWeight:600}}>SERO Official</a>},
    {"item":<span style={{fontWeight:500}}>Mining channel</span>,"detail":<a href="https://t.me/SEROPoolSupport" target="_blank" style={{fontWeight:600}}>SERO Official Pool</a>},
]

class HelpPage extends React.Component<any, any>{

    componentDidMount() {

    }

    render() {
        return (
            <LayoutDisplay menuKey="help">
                <div className="tab-info">
                    <Typography>
                        <Title>How to mine SERO</Title>
                        <Paragraph>
                            <p>Welcome , SERO it the world's first truly Privacy Protection platform for Decentralized Applications. </p>
                        </Paragraph>

                        <Title level={2}>Basic information</Title>
                        <Row>
                            <Col span="8">
                                <Table dataSource={datasource} columns={columns} size="small" pagination={{position:[]}}/>
                            </Col>
                        </Row>
                        <Title level={2}>Mining URLs</Title>
                        <Paragraph>
                            <ul>
                                <li><b>stratum+tcp://cartompool.com:8808</b></li>
                            </ul>
                        </Paragraph>

                        <Title level={2}>Mining Tools</Title>
                        <Paragraph>
                            <ul>
                                <li>
                                    <b>NBMiner(39.5)</b> (<Link href="https://github.com/NebuTech/NBMiner" target="_blank">https://github.com/NebuTech/NBMiner</Link>)
                                    <pre>nbminer.exe -a progpow_sero -o stratum+tcp://cartompool.com:8808 -u address.worker</pre>
                                </li>
                                <li>
                                    <b>T-Rex</b> (<Link href="https://github.com/trexminer/T-Rex" target="_blank">https://github.com/trexminer/T-Rex</Link>)
                                    <pre>t-rex.exe -a progpow --coin sero -o stratum+tcp://cartompool.com:8808 -u address -p x -w worker</pre>
                                </li>
                                <li>
                                    <b>TT-Miner</b> (<Link href="https://github.com/arihant-jain-09/TT-Miner" target="_blank">https://github.com/arihant-jain-09/TT-Miner</Link>)
                                    <pre>TT-Miner.exe -a PROGPOW -coin SERO -P address@cartompool.com:8808 -w worker</pre>
                                </li>
                            </ul>
                        </Paragraph>
                    </Typography>
                </div>
            </LayoutDisplay>
        );
    }
}

export default HelpPage