import React from 'react';
import {HashRouter as Router,Switch,Route} from 'react-router-dom'

import 'antd/dist/antd.css';
import Home from "./home";
import Blocks from "./blocks";
import Miners from "./miners";
import Details from "./miners/details";
import HelpPage from "./help";

function App() {
  return (
      <Router>
        <Switch>
            <Route exact path="/home"  component={Home}/>
            <Route exact path="/blocks"  component={Blocks}/>
            <Route exact path="/miners/:key"  component={Miners}/>
            <Route exact path="/account/:address"  component={Details}/>
            <Route exact path="/accounts"  component={Details}/>
            <Route exact path="/help"  component={HelpPage}/>

            <Route exact path="/"  component={Home}/>
        </Switch>
      </Router>
  );
}

export default App;
