import axios from 'axios';
import config from "../config";

class Rpc {

    post = async (url:string,data?:any)=>{
        const resp = await axios.post(url,data);
        return resp.data;
    }

    stats = async ()=>{
        const url = `${config.url.poolNode.api}/api/stats`;
        return this.post(url)
    }

    blocks = async ()=>{
        const url = `${config.url.poolNode.api}/api/blocks`;
        return this.post(url)
    }

    miners = async (key?:string)=>{
        const url = `${config.url.poolNode.api}/api/miners/${key}`;
        return this.post(url)
    }

    accounts = async (addr:string)=>{
        const url = `${config.url.poolNode.api}/api/accounts/${addr}`;
        return this.post(url)
    }

    exploreStats = async ()=>{
        const resp = await axios.get("https://explorerapi.sero.cash/minedSero");
        return resp.data;
    }

}
const rpc = new Rpc();

export default rpc