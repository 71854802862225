import * as React from "react";
import LayoutDisplay from "../layout";
import {Row, Col,Statistic,Divider,Table,Descriptions,Tabs,Typography,Input} from "antd";
import { Area } from '@ant-design/charts';
import { WalletOutlined, SearchOutlined, GlobalOutlined,SmileOutlined,SmileFilled } from '@ant-design/icons';
import "./index.css";
import rpc from "../rpc";
import utils, {statsInterVar} from "../utils";
import BigNumber from "bignumber.js";
const {Search} = Input;
const { Paragraph,Text, Link } = Typography;

const { TabPane } = Tabs;
function callback(key:any) {
    console.log(key);
}
interface State{
    data:any
    explorerStats:any,
    poolHashRateConfig:any
    netHashRateConfig:any
    netDifficultyConfig:any
    blocks:any
}

class Home extends React.Component<any, State>{

    state:State = {
        data:null,
        explorerStats:{},
        blocks: null,
        poolHashRateConfig : {
            data:[],
            height: 300,
            xField: 'label',
            yField: 'value',
            point: {
                size: 5,
                shape: 'diamond',
            },
            xAxis: {
                title:{text:"Time"},
            },
            yAxis: {
                title:{text:"GH/s"},
                label:{
                    formatter:(v:any)=>{
                        return `${v}`
                    }
                },
            },
            tooltip: {
                customItems: (originalItems: any[]) => {
                    // process originalItems,
                    console.log(originalItems)
                    originalItems[0].value = `${utils.nFormatter(new BigNumber(originalItems[0].value).multipliedBy(1e9),4)}/s`
                    return originalItems;
                }
            },
            // areaStyle:{
            //     fill: 'red',
            //     fillOpacity: 0.5,
            //     stroke: 'black',
            //     lineWidth: 1,
            //     lineDash: [4, 5],
            //     strokeOpacity: 0.7,
            //     shadowColor: 'black',
            //     shadowBlur: 10,
            //     shadowOffsetX: 5,
            //     shadowOffsetY: 5,
            //     cursor: 'pointer'
            // }
        },

        netHashRateConfig : {
            data:[],
            height: 300,
            xField: 'label',
            yField: 'value',
            point: {
                size: 5,
                shape: 'diamond',
            },
            xAxis: {
                title:{text:"Time"},
            },
            yAxis: {
                title:{text:"GH/s"},
                label:{
                    formatter:(v:any)=>{
                        return `${v}`
                    }
                },
            },
            tooltip: {
                customItems: (originalItems: any[]) => {
                    // process originalItems,
                    console.log(originalItems)
                    originalItems[0].value = `${utils.nFormatter(new BigNumber(originalItems[0].value).multipliedBy(1e9),4)}/s`
                    return originalItems;
                }
            }
        },

        netDifficultyConfig : {
            data:[],
            height: 300,
            xField: 'label',
            yField: 'value',
            point: {
                size: 5,
                shape: 'diamond',
            },
            xAxis: {
                title:{text:"Time"},
            },
            yAxis: {
                title:{text:"GH"},
                label:{
                    formatter:(v:any)=>{
                        return `${v}`
                    }
                },
            },
            tooltip: {
                customItems: (originalItems: any[]) => {
                    // process originalItems,
                    console.log(originalItems)
                    originalItems[0].value = `${utils.nFormatter(new BigNumber(originalItems[0].value).multipliedBy(1e9),4)}`
                    return originalItems;
                }
            },
            color: {
                colorField: 'type',
                color: ['#d62728', '#2ca02c', '#000000'],
            }
        }

    }

    componentDidMount() {
        // this.init().catch((e)=>{
        //     console.error(e)
        // })

        statsInterVar.start(()=>{
            this.init().catch(e=>{console.error(e)})
        },3000)
    }

    init = async ()=>{
        const {poolHashRateConfig,netDifficultyConfig,netHashRateConfig} = this.state;
        const data:any = await rpc.stats()
        const d:any = await rpc.exploreStats();
        const blks:any = await rpc.blocks();
        const netDifficultyLine:Array<any> = data.netHashrateLine;
        const netHashRateLine = [];
        if(netDifficultyLine){
            for(let l of netDifficultyLine){
                netHashRateLine.push({
                    label:l.label,
                    value: new BigNumber(l.value).dividedBy(14.4).dividedBy(1e9).toNumber()
                })
            }
        }
        const poolHashRateData:Array<any> = data.poolHashrateLine;
        poolHashRateData && poolHashRateData.forEach((v)=>{
            v.value = new BigNumber(v.value).dividedBy(1E9).toNumber()
        })
        netDifficultyLine && netDifficultyLine.forEach((v)=>{
            v.value = new BigNumber(v.value).dividedBy(1E9).toNumber()
        })
        poolHashRateConfig.data = poolHashRateData;

        netHashRateConfig.data = netHashRateLine;
        netDifficultyConfig.data = netDifficultyLine;

        this.setState({
            data:data, explorerStats: d,
            poolHashRateConfig:poolHashRateConfig,
            netHashRateConfig:netHashRateConfig,
            netDifficultyConfig:netDifficultyConfig,
            blocks:blks
        })
    }

    render() {
        const {data,explorerStats} = this.state;
        const {poolHashRateConfig,netDifficultyConfig,netHashRateConfig,blocks} = this.state;
        const bestNode = data ? data.nodes[0]:{};
        return (
            <div>
                <LayoutDisplay menuKey="home">
                    <div style={{marginBottom:"24px"}}>
                        <Row>
                            <Col offset={4} span={16}>
                                <Search placeholder="Address" size="large" onSearch={(v)=>{
                                    window.location.href=`#/account/${v}`
                                }} enterButton  />
                            </Col>
                        </Row>
                    </div>
                    <Row justify="center">
                        <Col lg={12} xl={8} xxl={8} sm={24} xs={24} md={24}>
                            <div className="pool-info">
                                <div style={{textAlign:"center"}}>
                                    {
                                        blocks && bestNode &&blocks["luck"][128] ?
                                            <Row>
                                                <Col span={8}>
                                                    <Statistic title="Block Height" value={data?bestNode.height+" ":0} valueStyle={{ color: '#1890ff',fontWeight:800 }}/>
                                                </Col>
                                                <Col span={16}>
                                                    {blocks && bestNode &&blocks["luck"][128] && <Statistic title={`Estimated Daily Revenue`} valueStyle={{ fontSize: '12px !important' }}
                                                                                                            value={`1MH/s = ${new BigNumber(24*3600*4.4*1024*1024).multipliedBy(1-blocks["luck"][128].orphanRate).dividedBy(new BigNumber(bestNode["difficulty"])).toFixed(4,1)} SERO`} />}
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col span={8}>
                                                    <Statistic title="Block Height" value={data?bestNode.height+" ":0} valueStyle={{ color: '#1890ff',fontWeight:800 }}/>
                                                </Col>
                                                <Col span={16}>
                                                    <Statistic title={`Estimated Daily Revenue`} valueStyle={{ fontSize: '12px !important' }} value={`1 MH/s =0 SERO`} />
                                                </Col>
                                            </Row>
                                    }

                                </div>
                                <Divider/>
                                <div style={{textAlign:"center"}}>
                                    <h3>Mining URL</h3>
                                    <p>
                                        <Paragraph copyable={{ tooltips: false }}>
                                            stratum1+tcp://cartompool.com:8808
                                        </Paragraph>
                                    </p>

                                </div>
                                <Divider/>
                                <Row style={{textAlign:"center"}}>
                                    <Col span={8}>
                                        <Statistic title="Min Payout" value="0.5" suffix="SERO" />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Payouts Time" value="Twice per day" />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Pool Fee Rate" value="2" suffix="%" />
                                    </Col>
                                </Row>
                                <Divider/>
                                <div style={{textAlign:"center"}}>
                                    <Row>
                                        <Col span={8}>
                                            <Link href="https://sero.cash/app/popup.html" target="_blank">
                                                <WalletOutlined style={{fontSize:"24px"}}/><br/>Wallet
                                            </Link>
                                        </Col>
                                        <Col span={8}>
                                            <Link href="https://sero.cash" target="_blank">
                                                <GlobalOutlined style={{fontSize:"24px"}}/><br/>Website
                                            </Link>
                                        </Col>
                                        <Col span={8}>
                                            <Link href="https://explorer.sero.cash" target="_blank">
                                            <SearchOutlined style={{fontSize:"24px"}}/><br/>Explorer
                                        </Link></Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} xl={16} xxl={16} sm={24} xs={24} md={24}>
                            <div className="statistic-info">
                                <Row>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="Pool Hash Rate" value={utils.nFormatter(data&&data.hashrate?data.hashrate:0,3)} valueStyle={{ color: '#cf1322' }}/>
                                    </Col>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="Network Difficulty" value={utils.nFormatter(data&&bestNode.difficulty?bestNode.difficulty:0,3)} />
                                    </Col>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="Network Hash Rate" value={utils.nFormatter((data&&bestNode.difficulty?bestNode.difficulty:0)/14.4,3)} />
                                    </Col>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="Last Block Found" value={`${utils.dateAge(data&&data.stats&&data.stats.lastBlockFound?data.stats.lastBlockFound:0)}`} />
                                    </Col>
                                </Row>
                                <Divider/>
                                <Row>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="Block Time" value={`${explorerStats&&explorerStats.BT?explorerStats.BT:0}`} suffix="sec" />
                                    </Col>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="Block Reward" value={`${explorerStats&&explorerStats.BR?explorerStats.BR:0} `} suffix="SERO" />
                                    </Col>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="SERO Price" value={data?data["qprice"]:0} prefix="$" valueStyle={{ color: '#3f8600' }}/>
                                    </Col>
                                    <Col sm={8} xs={12} md={6} lg={6} xl={6} xxl={6}>
                                        <Statistic title="Variance" value={data&&data.stats.roundShares ? new BigNumber(data.stats.roundShares).dividedBy(bestNode.difficulty).multipliedBy(100).toFixed(0):0} suffix="%" />
                                    </Col>
                                </Row>
                            </div>
                            <div className="desc-info">
                                This pool was established in Frankfurt by SERO Community, it is currently closed to&nbsp;
                                <Text type="warning">mainland China,Burundi,Central African Republic, Congo, Cuba, Iraq, Iran, North Korea, Lebanon, Libya, Sudan, Somalia, South Sudan, Syria, Yemen, Zimbabwe &nbsp;</Text>
                                 according to the regulations of these countries.

                            </div>
                        </Col>
                    </Row>
                    <div className="tab-info">
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Pool Hash Rate" key="1">
                                <div>
                                    <Area
                                        {...poolHashRateConfig}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Network Hash Rate" key="2">
                                <div>
                                    <Area
                                        {...netHashRateConfig}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Network Difficulty" key="3">
                                <div>
                                    <Area
                                        {...netDifficultyConfig}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </LayoutDisplay>
            </div>
        );
    }
}

export default Home